<div class="d-flex">
  @if(imageUrl){
  <img
    [src]="imageUrl"
    [alt]="altText"
    [style]="{ 'width.px': size, 'height.px': size }"/>
  }@else if(type=='normal') {
  <div class="user-initials" [style.background-color]="color">
    {{ initial }}
  </div>
  }@else if (type=='small') {
  <label
    class="user-initials user-initials-sm text-white" [style.background-color]="color">
    {{ initial }}
  </label>
  }
  @if(firstName && LastName){
    <span class="user-name ms-1">{{ firstName }} {{ LastName }}</span>
  }
</div>
