import { Pipe, PipeTransform } from '@angular/core';
import { AppSettingsStore } from '../store/app-settings.store';
import moment, * as momentT from 'moment-timezone';

@Pipe({
  name: 'dateTimeFormat',
  standalone: true,
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(private appSettingsStore: AppSettingsStore) {}

  transform(value: any): any {
    if (!value || !this.isValidDate(value)) {
      return '';
    }

    const isDateTime = moment(value, moment.ISO_8601, true).isValid() && value.includes('T');
    const format = isDateTime ? this.appSettingsStore.dateTimeFormat : this.appSettingsStore.dateFormat;
    const timezone = this.appSettingsStore.timezone || moment.tz.guess();

    return isDateTime ? moment(value).tz(timezone).format(format) : moment(value).format(format);
  }

  private isValidDate(value: any): boolean {
    return moment(value, moment.ISO_8601, true).isValid() || moment(value, this.appSettingsStore.dateFormat, true).isValid();
  }
}
