import { Injectable } from '@angular/core';
import { makeAutoObservable, toJS } from 'mobx';
import { OrganizationLevelService } from '../../features/settings/general-settings/services/organization-level.service';
import { OrganizationLevelDTO } from '../../features/settings/general-settings/dto/organization-level.dto';
import { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsStore {
  totalLevels: number = 0;
  primaryLevel: OrganizationLevelDTO | null = null;
  levels: OrganizationLevelDTO[] = [];
  dateFormat: string = 'MMM D, YYYY';
  dateTimeFormat: string = 'MMM D, YYYY h:mm A';
  datePickerFormat: string = 'Y-m-d';
  dateTimePickerFormat: string = 'Y-m-d\\TH:i:S';
  timezone: string = 'Asia/Dubai';

  constructor(private organizationLevelService: OrganizationLevelService) {
    makeAutoObservable(this);
  }

  loadOrganizationLevels(): Observable<OrganizationLevelDTO[]> {
    return this.organizationLevelService.getOrganizationLevels();
  }

  async initialize(): Promise<void> {
    try {
      const data = await firstValueFrom(this.loadOrganizationLevels());
      this.levels = data;
      this.primaryLevel = data.find((level) => level.is_primary) || null;
      this.totalLevels = Math.max(...data.map((item) => item.level));
    } catch (error) {
      console.error('Failed to load organization levels', error);
    }
  }

  get primaryLevelData() {
    return toJS(this.primaryLevel);
  }

  get organizationLevels() {
    return toJS(this.levels);
  }
}
