import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'app-user-image',
  standalone: true,
  imports: [],
  templateUrl: './user-image.component.html',
  styleUrl: './user-image.component.scss',
})
export class UserImageComponent {
  @Input() type: 'small' | 'normal' = 'normal';
  @Input() imageUrl: string | null;
  @Input() altText: string;
  @Input() size: number;
  @Input() color: string;
  @Input() initial: string;
  @Input() firstName: string;
  @Input() LastName: string;
}
